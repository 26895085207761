@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Noto+Sans&display=swap');


body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-change {
  font-family: 'Aldrich', sans-serif;
}

.block-color {
  background-color: rgba(13, 24, 43, 0.92);
}

.image {
  background-image: url(../src/assets/images/hardhat-near-schemes-tools.png);
  background-repeat: no-repeat;
  background-size: cover;
}
